<template>
  <transition name="slide">
    <div class="subpage">
      <PageText>
        <template v-slot:title>{{ $route.meta.title }}</template>
        <template v-slot:subtitle v-if="$route.meta.subtitle">{{ $route.meta.subtitle }}</template>
        <template v-slot:text>
          <p class="page-text__text">
            Czy wybitny intelektualista, założyciel uniwersytetu, wielki myśliciel, teolog i kaznodzieja, uznawany za jednego
            z mistrzów języka angielskiego, żyjący w XIX wieku, może nam być bliski? Oczywiście! Wielka pokora, umiłowanie
            prawdy oraz świętość życia są przecież ponadczasowe!
          </p>
          <p class="page-text__text">
            Jan Henryk Newman, bo to o nim mowa, urodził się w 1801 roku w anglikańskiej rodzinie. Kiedy miał 16 lat
            rozpoczął studia w Oksfordzie. Od samego początku przejawiał duże zdolności intelektualne. Jako duchowny
            anglikański został jednym z przywódców ruchu oksfordzkiego, który miał na celu odnowę anglikanizmu. Działalność w
            ruchu ostatecznie zaprowadziła go do Kościoła katolickiego. Odkrył, że właśnie tam jest prawdziwie Jezus Chrystus
            – Prawda, której tak gorliwie szukał. 9 października 1845 roku został przyjęty do Kościoła katolickiego.
          </p>
          <p class="page-text__text">
            Ten krok był dla niego trudną decyzją, ale nie wahał się go zrobić. Konwersja oznaczała porzucenie środowiska,
            które dobrze znał i w którym wzrastał, ale również odsunięcie się od swoich przyjaciół. Wśród katolików
            początkowo było mu trudno; jako znacząca figura anglikanizmu, a potem konwertyta wzbudzał podejrzliwość. Ale
            szedł tam, dokąd prowadziło go sumienie. Po swojej konwersji przyjął święcenia kapłańskie. Wstąpił też do
            Oratorium św. Filipa Neri i założył pierwsze oratorium w Anglii.
          </p>
          <p class="page-text__text">
            Jako człowiek wielkiej mądrości oraz autorytetu wywierał duży wpływ na środowisko katolików w Anglii. W uznaniu
            jego zasług papież mianował go kardynałem. Jako jeden z pierwszych teologów zwrócił uwagę na rolę świeckich w
            Kościele. Jego myśli i poglądy zostały docenione w czasie Soboru Watykańskiego II. Dziś uważany jest za jego
            prekursora.
          </p>
          <p class="page-text__text">
            Umarł w 1890 roku. Świętość Newmana została potwierdzona kanonizacją, która miała miejsce 19 października 2019
            roku. Jego życie to przykład nieustannego poszukiwania prawdy. Szukał jej w wielkiej pokorze i cichości – przy
            biurku oraz na modlitwie. Kiedy wpadł na jej trop, nie wahał się pójść za nim, nawet jeśli wiązało się to z
            niedogodnościami i narażaniem się na różne zarzuty. Pan Bóg był dla niego na pierwszym miejscu i potwierdził to
            całym swoim życiem.
          </p>
          <p class="page-text__text">Św. Janie Henryku Newmanie, ucz nas pokory i umiłowania prawdy!</p>
          <div class="btn" style="display: flex">
            <router-link to="/patroni">
              <Btn text="Powrót" :reverse="true" />
            </router-link>
          </div>
        </template>
      </PageText>
    </div>
  </transition>
</template>

<script>
export default {
  components: {},
};
</script>
<style lang="scss" scoped>
</style>
